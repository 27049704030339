import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCFxTjVTzEuY5w8Nf-gqlcPOp1MQ5-wXd8",
  authDomain: "perceptions-2k25.firebaseapp.com",
  databaseURL: "gs://perceptions-2k25.firebasestorage.app",
  projectId: "perceptions-2k25",
  storageBucket: "perceptions-2k25.firebasestorage.app",
  messagingSenderId: "564357605601",
  appId: "1:564357605601:web:3389d711bd396e87b2efa0",
  measurementId: "G-5WYNWJHPP8"
};

const app = initializeApp(firebaseConfig);

// Only initialize analytics if window is available (client-side)
let analytics = null;
if (typeof window !== 'undefined') {
  analytics = getAnalytics(app);
}

export const db = getFirestore(app);
export const storage = getStorage(app);
export { analytics };