import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { submitPayment } from '../services/registration';
import { Upload } from 'lucide-react';
import toast from 'react-hot-toast';

export const PaymentPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { teamId, totalAmount } = location.state || {};
  const [utrNumber, setUtrNumber] = useState('');
  const [remitterName, setRemitterName] = useState('');
  const [screenshot, setScreenshot] = useState<File | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!screenshot) {
      toast.error('Please upload payment screenshot');
      return;
    }

    try {
      await submitPayment(teamId, {
        utrNumber,
        screenshot,
        remitterName
      });
      toast.success('Payment details submitted successfully!');
      navigate('/confirmation');
    } catch (error) {
      toast.error('Error submitting payment details');
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-6">
      <div className="backdrop-blur-md bg-white/30 rounded-lg shadow-lg p-6">
        <h2 className="text-2xl font-bold text-brown-800 mb-6">Payment Details</h2>
        
        <div className="mb-6">
          <h3 className="text-lg font-semibold text-brown-700 mb-2">Team ID: {teamId}</h3>
          <div className="bg-brown-50/50 p-4 rounded-lg">
            <p className="text-brown-800 font-medium">Amount to Pay: ₹{totalAmount}</p>
            <div className="mt-4">
              <img 
                src="/payment-qr.png" 
                alt="Payment QR Code"
                className="max-w-[200px] mx-auto"
              />
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-brown-700 mb-1">
              UTR Number
            </label>
            <input
              type="text"
              value={utrNumber}
              onChange={(e) => setUtrNumber(e.target.value)}
              className="w-full px-4 py-2 rounded-md border border-brown-300 focus:ring-2 focus:ring-brown-500 focus:border-transparent bg-white/50 backdrop-blur-sm"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-brown-700 mb-1">
              Remitter's Name
            </label>
            <input
              type="text"
              value={remitterName}
              onChange={(e) => setRemitterName(e.target.value)}
              className="w-full px-4 py-2 rounded-md border border-brown-300 focus:ring-2 focus:ring-brown-500 focus:border-transparent bg-white/50 backdrop-blur-sm"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-brown-700 mb-1">
              Payment Screenshot
            </label>
            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-brown-300 border-dashed rounded-md">
              <div className="space-y-1 text-center">
                <Upload className="mx-auto h-12 w-12 text-brown-400" />
                <div className="flex text-sm text-brown-600">
                  <label className="relative cursor-pointer rounded-md font-medium text-brown-600 hover:text-brown-500">
                    <span>Upload a file</span>
                    <input
                      type="file"
                      className="sr-only"
                      accept="image/*,.pdf"
                      onChange={(e) => setScreenshot(e.target.files?.[0] || null)}
                      required
                    />
                  </label>
                </div>
                <p className="text-xs text-brown-500">PNG, JPG, PDF up to 10MB</p>
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="w-full py-3 bg-brown-800 text-white rounded-md hover:bg-brown-900 transition-colors font-medium"
          >
            Submit Payment Details
          </button>
        </form>
      </div>
    </div>
  );
};