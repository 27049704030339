import React from 'react';
import { TeamMember } from '../types';
import { X } from 'lucide-react';

interface TeamMemberFormProps {
  member: TeamMember;
  index: number;
  onUpdate: (index: number, field: keyof TeamMember, value: string) => void;
  onRemove: (index: number) => void;
}

export const TeamMemberForm: React.FC<TeamMemberFormProps> = ({
  member,
  index,
  onUpdate,
  onRemove,
}) => {
  return (
    <div className="p-6 backdrop-blur-md bg-white/30 rounded-lg shadow-lg mb-4">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold text-brown-800">Team Member {index + 1}</h3>
        <button
          onClick={() => onRemove(index)}
          className="text-brown-600 hover:text-brown-800 transition-colors"
        >
          <X size={20} />
        </button>
      </div>
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-brown-700 mb-1">
            Full Name
          </label>
          <input
            type="text"
            value={member.fullName}
            onChange={(e) => onUpdate(index, 'fullName', e.target.value)}
            className="w-full px-4 py-2 rounded-md border border-brown-300 focus:ring-2 focus:ring-brown-500 focus:border-transparent bg-white/50 backdrop-blur-sm"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-brown-700 mb-1">
            Phone Number
          </label>
          <input
            type="tel"
            value={member.phoneNumber}
            onChange={(e) => onUpdate(index, 'phoneNumber', e.target.value)}
            className="w-full px-4 py-2 rounded-md border border-brown-300 focus:ring-2 focus:ring-brown-500 focus:border-transparent bg-white/50 backdrop-blur-sm"
            required
            pattern="[0-9]{10}"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-brown-700 mb-1">
            Email
          </label>
          <input
            type="email"
            value={member.email}
            onChange={(e) => onUpdate(index, 'email', e.target.value)}
            className="w-full px-4 py-2 rounded-md border border-brown-300 focus:ring-2 focus:ring-brown-500 focus:border-transparent bg-white/50 backdrop-blur-sm"
            required
          />
        </div>
      </div>
    </div>
  );
};