import { db, storage } from '../config/firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { RegistrationForm } from '../types';
import { handleFirebaseError } from '../utils/firebase-error-handler';

export const submitRegistration = async (formData: RegistrationForm) => {
  try {
    const teamId = generateTeamId();
    const registrationRef = await addDoc(collection(db, 'registrations'), {
      ...formData,
      timestamp: serverTimestamp(),
      status: 'pending',
      teamId,
    });
    
    return { success: true, teamId: registrationRef.id };
  } catch (error) {
    const errorMessage = handleFirebaseError(error);
    throw new Error(errorMessage);
  }
};

export const submitPayment = async (teamId: string, paymentData: {
  utrNumber: string,
  screenshot: File,
  remitterName: string
}) => {
  try {
    const screenshotRef = ref(storage, `payment-screenshots/${teamId}-${Date.now()}`);
    await uploadBytes(screenshotRef, paymentData.screenshot);
    const screenshotUrl = await getDownloadURL(screenshotRef);

    await addDoc(collection(db, 'payments'), {
      teamId,
      utrNumber: paymentData.utrNumber,
      remitterName: paymentData.remitterName,
      screenshotUrl,
      timestamp: serverTimestamp(),
      status: 'pending'
    });

    return { success: true };
  } catch (error) {
    const errorMessage = handleFirebaseError(error);
    throw new Error(errorMessage);
  }
};

const generateTeamId = () => {
  const prefix = 'PER25';
  const random = Math.random().toString(36).substring(2, 8).toUpperCase();
  return `${prefix}-${random}`;
};