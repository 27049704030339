export const handleFirebaseError = (error: any): string => {
  console.error('Firebase Error:', error);

  if (error.code) {
    switch (error.code) {
      case 'permission-denied':
        return 'Access denied. Please check your permissions.';
      case 'unavailable':
        return 'Service temporarily unavailable. Please try again later.';
      case 'not-found':
        return 'Requested resource not found.';
      case 'already-exists':
        return 'This record already exists.';
      case 'failed-precondition':
        return 'Operation failed. Please check your input.';
      case 'invalid-argument':
        return 'Invalid input provided.';
      default:
        return 'An error occurred. Please try again.';
    }
  }

  return 'An unexpected error occurred. Please try again.';
};