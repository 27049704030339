import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { RegistrationForm } from './components/RegistrationForm';
import { PaymentPage } from './components/PaymentPage';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <BrowserRouter>
      <div 
        className="min-h-screen bg-gradient-to-br from-brown-50 via-brown-100 to-brown-200 py-12"
        style={{
          backgroundImage: `url('https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?auto=format&fit=crop&q=80')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold text-brown-900 mb-4">
              Perceptions 2k25
            </h1>
            <p className="text-lg text-brown-700">
              Register your team for the ultimate management fest experience
            </p>
          </div>
          <Routes>
            <Route path="/" element={<RegistrationForm />} />
            <Route path="/payment" element={<PaymentPage />} />
          </Routes>
        </div>
        <Toaster position="top-right" />
      </div>
    </BrowserRouter>
  );
}

export default App;