export interface TeamMember {
  fullName: string;
  phoneNumber: string;
  email: string;
}

export interface RegistrationForm {
  leadName: string;
  collegeName: string;
  collegeLocation: string;
  phoneNumber: string;
  email: string;
  selectedEvent: string;
  teamMembers: TeamMember[];
}

export const EVENTS = [
  'Business Plan',
  'Case Study',
  'Marketing Strategy',
  'Finance Quiz',
  'HR Challenge',
  'Stock Trading',
  'Digital Marketing',
  'Product Launch',
  'Brand Building',
  'Supply Chain',
  'Data Analytics',
  'Business Quiz',
  'Startup Pitch',
  'Crisis Management',
  'Innovation Challenge',
  'Leadership Summit'
] as const;