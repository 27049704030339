import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { TeamMemberForm } from './TeamMemberForm';
import { RegistrationForm as RegistrationFormType, TeamMember, EVENTS } from '../types';
import { UserPlus, Users } from 'lucide-react';
import toast from 'react-hot-toast';
import { submitRegistration } from '../services/registration';

export const RegistrationForm: React.FC = () => {
  const navigate = useNavigate();
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const { register, handleSubmit, formState: { errors } } = useForm<RegistrationFormType>();

  const addTeamMember = () => {
    if (teamMembers.length >= 4) {
      toast.error('Maximum 5 members allowed (including team lead)');
      return;
    }
    setTeamMembers([...teamMembers, { fullName: '', phoneNumber: '', email: '' }]);
  };

  const updateTeamMember = (index: number, field: keyof TeamMember, value: string) => {
    const updatedMembers = [...teamMembers];
    updatedMembers[index] = { ...updatedMembers[index], [field]: value };
    setTeamMembers(updatedMembers);
  };

  const removeTeamMember = (index: number) => {
    setTeamMembers(teamMembers.filter((_, i) => i !== index));
  };

  const calculateAmount = (memberCount: number) => {
    const basePrice = 1000;
    const additionalMemberPrice = 200;
    return basePrice + (additionalMemberPrice * memberCount);
  };

  const onSubmit = async (data: RegistrationFormType) => {
    try {
      const formData = {
        ...data,
        teamMembers,
      };
      
      const { teamId } = await submitRegistration(formData);
      const totalAmount = calculateAmount(teamMembers.length);
      
      navigate('/payment', { 
        state: { 
          teamId,
          totalAmount
        }
      });
    } catch (error) {
      toast.error('Error submitting registration');
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="max-w-3xl mx-auto p-6 space-y-8">
      <div className="backdrop-blur-md bg-white/30 rounded-lg shadow-lg p-6">
        <div className="flex items-center gap-2 mb-6">
          <Users className="text-brown-700" size={24} />
          <h2 className="text-2xl font-bold text-brown-800">Team Lead Information</h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-brown-700 mb-1">
              Full Name
            </label>
            <input
              type="text"
              {...register('leadName', { required: 'Full name is required' })}
              className="w-full px-4 py-2 rounded-md border border-brown-300 focus:ring-2 focus:ring-brown-500 focus:border-transparent bg-white/50 backdrop-blur-sm"
            />
            {errors.leadName && (
              <p className="text-red-500 text-sm mt-1">{errors.leadName.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-brown-700 mb-1">
              College Name
            </label>
            <input
              type="text"
              {...register('collegeName', { required: 'College name is required' })}
              className="w-full px-4 py-2 rounded-md border border-brown-300 focus:ring-2 focus:ring-brown-500 focus:border-transparent bg-white/50 backdrop-blur-sm"
            />
            {errors.collegeName && (
              <p className="text-red-500 text-sm mt-1">{errors.collegeName.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-brown-700 mb-1">
              College Location
            </label>
            <input
              type="text"
              {...register('collegeLocation', { required: 'College location is required' })}
              className="w-full px-4 py-2 rounded-md border border-brown-300 focus:ring-2 focus:ring-brown-500 focus:border-transparent bg-white/50 backdrop-blur-sm"
            />
            {errors.collegeLocation && (
              <p className="text-red-500 text-sm mt-1">{errors.collegeLocation.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-brown-700 mb-1">
              Phone Number
            </label>
            <input
              type="tel"
              {...register('phoneNumber', {
                required: 'Phone number is required',
                pattern: {
                  value: /^[0-9]{10}$/,
                  message: 'Please enter a valid 10-digit phone number',
                },
              })}
              className="w-full px-4 py-2 rounded-md border border-brown-300 focus:ring-2 focus:ring-brown-500 focus:border-transparent bg-white/50 backdrop-blur-sm"
            />
            {errors.phoneNumber && (
              <p className="text-red-500 text-sm mt-1">{errors.phoneNumber.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-brown-700 mb-1">
              Email
            </label>
            <input
              type="email"
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Please enter a valid email address',
                },
              })}
              className="w-full px-4 py-2 rounded-md border border-brown-300 focus:ring-2 focus:ring-brown-500 focus:border-transparent bg-white/50 backdrop-blur-sm"
            />
            {errors.email && (
              <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-brown-700 mb-1">
              Select Event
            </label>
            <select
              {...register('selectedEvent', { required: 'Please select an event' })}
              className="w-full px-4 py-2 rounded-md border border-brown-300 focus:ring-2 focus:ring-brown-500 focus:border-transparent bg-white/50 backdrop-blur-sm"
            >
              <option value="">Select an event</option>
              {EVENTS.map((event) => (
                <option key={event} value={event}>
                  {event}
                </option>
              ))}
            </select>
            {errors.selectedEvent && (
              <p className="text-red-500 text-sm mt-1">{errors.selectedEvent.message}</p>
            )}
          </div>
        </div>
      </div>

      <div className="space-y-4">
        {teamMembers.map((member, index) => (
          <TeamMemberForm
            key={index}
            member={member}
            index={index}
            onUpdate={updateTeamMember}
            onRemove={removeTeamMember}
          />
        ))}

        <button
          type="button"
          onClick={addTeamMember}
          className="flex items-center gap-2 px-4 py-2 bg-brown-600 text-white rounded-md hover:bg-brown-700 transition-colors"
        >
          <UserPlus size={20} />
          Add Team Member
        </button>
      </div>

      <button
        type="submit"
        className="w-full py-3 bg-brown-800 text-white rounded-md hover:bg-brown-900 transition-colors font-medium"
      >
        Submit Registration
      </button>
    </form>
  );
};